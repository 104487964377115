/* eslint-disable quote-props */
import { createHttpClient } from '../utils/httpUtils';

class ViewService {
  constructor() {
    this.http = createHttpClient();
  }

  async show(viewId) {
    const res = await this.http.get('views/' + viewId).catch((error) => {
      console.log(error.response);
      return false;
    });
    return res ? res.data : res;
  }

  async showByProyect(sectorId) {
    const res = await this.http
      .get(`views/proyect/${undefined}/sector/${sectorId}`)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : [];
  }

  async destroy(viewId) {
    const view = await this.getUpdatedView(viewId);
    if (this.havePermissionToEditOrDeleteView(view)) {
      const res = await this.http.delete('views/' + viewId).catch((error) => {
        console.log(error.response);
        return false;
      });
      return res ? res.data : res;
    }
    return;
  }

  populateSectorAndUser(view, currentSector) {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    if (currentUser && !view.userId) {
      view.userId = currentUser.id;
    }
    if (currentSector && !view.sectorId) {
      view.sectorId = currentSector.id;
    }
  }

  async getUpdatedView(viewId) {
    const response = await this.show(viewId);
    if (!response) return;
    const view = response.view;
    if (!view) return;
    return view;
  }

  async create(view) {
    this.populateSectorAndUser(view, view.sectorId);
    const res = await this.http.post('views', view).catch((error) => {
      console.log(error.response);
      return false;
    });
    return res ? res.data : res;
  }

  havePermissionToEditOrDeleteView(view) {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    const admitRolesToEditView = ['superadmin', 'admin'];
    const isPublicAndCreatorView = view.public && view.userId == currentUser.id;
    const isCreatorView = view.userId == currentUser.id;
    const isRoleEditableView =
      view.public && admitRolesToEditView.includes(currentUser.role);
    return isPublicAndCreatorView || isCreatorView || isRoleEditableView;
  }

  async updateLabel(viewId, label) {
    const view = await this.getUpdatedView(viewId);

    if (!view) throw new Error('Update error');

    if (view && this.havePermissionToEditOrDeleteView(view)) {
      const viewData = {
        ...view,
        label
      };
      const res = await this.http
        .put('views/' + view.id, viewData)
        .catch((error) => {
          console.log(error.response);
          return false;
        });
      return res ? res.data : res;
    }
    return;
  }

  async update(view) {
    if (view.public) {
      return;
    }
    const currentUser = JSON.parse(localStorage.getItem('user'));
    if (view.userId !== currentUser.id) return;
    const viewData = {
      ...view,
      order: view.orderView,
      ganttChart: view.ganttChart
    };
    const res = await this.http
      .put('views/' + view.id, viewData)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async updateDefaultView(projectId, sectorId, defaultView) {
    const res = await this.http
      .put(
        `views?defaultView=${defaultView}&project=${projectId}&sector=${sectorId}`
      )
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async updateDefaultViewByView(viewId, defaultView) {
    const res = await this.http
      .put(`views?defaultView=${defaultView}&view=${viewId}`)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }
}

export const viewService = new ViewService();
