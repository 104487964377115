/* eslint-disable quote-props */
import { createHttpClient } from '../utils/httpUtils';
import { base } from './base';
import * as Sentry from '@sentry/react';

class SectorService {
  constructor() {
    this.http = createHttpClient();
  }

  async show(sectorId) {
    if (!this.http || !sectorId) {
      console.warn('No session token available, request aborted');
      return false;
    }
    const res = await this.http.get('sectors/' + sectorId).catch((error) => {
      console.log(error.response);
      return false;
    });
    return res ? res.data : res;
  }

  async showByLooaheadWeekly(sectorId) {
    const res = await this.http
      .get('sectors/showByLooaheadWeekly/' + sectorId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async showActivity(sectorId) {
    const res = await this.http
      .get('sectors/activity/' + sectorId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async showActivityPoints(sectorId) {
    const res = await this.http
      .get('sectors/activityponts/' + sectorId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async showActivityVersions(sectorId) {
    const res = await this.http
      .get('sectors/activityversions/' + sectorId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async showActivityTaskCount(sectorId) {
    const res = await this.http
      .get('sectors/activitytaskscount/' + sectorId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async createActivitiesTree(sectorId) {
    const axiosObject = createHttpClient({ baseURL: base.api });
    const res = await axiosObject
      .get('sectors/activitiestree/' + sectorId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async createActivitiesTreeForActivityTree(sectorId) {
    const axiosObject = createHttpClient({ baseURL: base.api });
    const res = await axiosObject
      .get('sectors/activitiestreeforfilter/' + sectorId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async showActivityRelation(sectorId) {
    const res = await this.http
      .get('sectors/activityrelation/' + sectorId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async showCalendar(sectorId) {
    const res = await this.http
      .get('sectors/calendar/' + sectorId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async showBaseCalendar(sectorId) {
    const res = await this.http
      .get('sectors/basecalendar/' + sectorId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async destroy(sectorId) {
    const res = await this.http.delete('sectors/' + sectorId).catch((error) => {
      console.log(error.response);
      return false;
    });
    return res ? res.data : res;
  }

  async index() {
    const res = await this.http.get('sectors').catch((error) => {
      console.log(error.response);
      return false;
    });
    return res ? res.data : res;
  }

  async create(data) {
    this.validateDateFormat(data);
    const res = await this.http.post('sectors', data).catch((error) => {
      console.log(error.response);
      return false;
    });
    return res ? res.data : res;
  }

  async updatePonderatorLookahead(sectorId, update) {
    const res = await this.http
      .post('sectors/updateponderatorlookahead', { sectorId, update })
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async updatePonderatorMaster(sectorId, update) {
    const res = await this.http
      .post('sectors/updateponderatormaster', { sectorId, update })
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async updateprimaveradurationmaster(sectorId, update) {
    const res = await this.http
      .post('sectors/updateprimaveradurationmaster', { sectorId, update })
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async updatecalendarsavemaster(sectorId, update) {
    const res = await this.http
      .post('sectors/updatecalendarsavemaster', { sectorId, update })
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async update(data) {
    const { dateFormat } = data;
    if (!dateFormat) this.validateDateFormat(data);
    const res = await this.http
      .put('sectors/' + data.id, data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  validateDateFormat(sector) {
    if (!sector.dateFormat && window.translateObject) {
      sector.dateFormat =
        window.translateObject('lang') === 'en' ? 'MM/DD/YY' : 'DD/MM/YY';
    } else {
      sector.dateFormat = 'DD/MM/YY';
    }
  }

  async getSectorsByProject(projectId) {
    if (!this.http || !projectId) {
      console.warn('No session token available, request aborted');
      return false;
    }
    const res = await this.http
      .get('sectors/project/' + projectId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async getSectorsByProjectWithMasterActivity(projectId) {
    const res = await this.http
      .get('sectors/projectwithmasteractivity/' + projectId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async updateFieldsBySectorId(sectorId, values) {
    try {
      const res = await this.http.put(`sectors/values/${sectorId}`, { values });
      return res.data;
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setContext('updateFieldsBySectorId', {
          message: error.message,
          values
        });
        Sentry.captureException(error);
      });
      console.log('Error in updateFieldsBySectorId: ', error);
      throw error(error);
    }
  }

  async duplicateSector(values) {
    const axiosObject = createHttpClient({ baseURL: base.apiTakt });
    const res = await axiosObject
      .post('sectors/duplicate', values)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async duplicateEverything(values) {
    const axiosObject = createHttpClient({ baseURL: base.apiTakt });
    const res = await axiosObject
      .post('sectors/duplicate/everything', values)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }
}

export const sectorService = new SectorService();
