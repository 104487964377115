/* eslint-disable quote-props */
import * as Sentry from '@sentry/react';
import { trackingEvent } from '../analytics';
import { getBasicAmplitudEventProperties } from '../analytics/utils';
import { AMPLITUDE_SERVICE } from '../analytics/constants';
import { createHttpClient } from '../utils/httpUtils';

class ActivityService {
  constructor() {
    this.http = createHttpClient({
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
      timeout: 300000
    });
  }

  async exportToPDF(data) {
    const res = await this.http
      .post('activitys/exportpdf', data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async exportToXML(data) {
    try {
      const res = await this.http
        .post('activitys/exportxml', data)
        .catch((error) => {
          console.log(error.response);
          return false;
        });
      return res ? res.data : res;
    } catch (error) {
      console.log(error);
    }
  }

  async show(activityId) {
    const res = await this.http
      .get('activitys/' + activityId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async showChilds(data) {
    const res = await this.http
      .post('activitys/showchilds', data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async showCircular(data) {
    const res = await this.http
      .post('activitys/showcircular', data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async showBySector(sectorId) {
    const res = await this.http
      .get('activitys/sector/' + sectorId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async destroy(activityId) {
    const res = await this.http
      .delete('activitys/' + activityId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async create(activity) {
    const res = await this.http.post('activitys', activity).catch((error) => {
      console.log(error.response);
      return false;
    });
    return res ? res.data : res;
  }

  async update(activity) {
    try {
      const res = await this.http.put('activitys/' + activity.id, activity);
      return res.data;
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setContext('updateActivityService', {
          message: error.message,
          activity
        });
        Sentry.captureException(error);
      });

      throw error;
    }
  }

  async createAll(activities, sector_id) {
    const res = await this.http
      .post('activitys/allv2', { activities: activities, sector_id: sector_id })
      .catch((error) => {
        console.log(error, 'error create all');
        const responseSave = error.response
          ? {
              status: error.response.status,
              statusText: error.response.statusText,
              data: error.response.data,
              sector_id
            }
          : { error };
        Sentry.withScope((scope) => {
          scope.setExtra('responseSave', responseSave);
          activities.forEach((activity) =>
            scope.setExtra(`activity_id ${activity.id}`, activity)
          );
          Sentry.captureException(new Error('cant save activities'));
        });
        trackingEvent(
          'save_schedule_failed_dev',
          {
            ...getBasicAmplitudEventProperties(),
            error_information: responseSave
          },
          AMPLITUDE_SERVICE
        );
        return false;
      });
    return res ? res.data : res;
  }

  async updateResponsiblesAndTags(activities, sectorId) {
    const res = await this.http
      .post('activitys/updateResponsiblesAndTags', {
        activities: activities,
        sector_id: sectorId
      })
      .catch((error) => {
        console.log('Failed to update responsibles and tags', error);
        return false;
      });
    return res ? res.data : res;
  }

  async createAllChangeTaskDate(activities, sector_id, currentGanttData) {
    const res = await this.http
      .post('activitys/allChangeTaskDate', {
        activities: activities,
        sector_id: sector_id,
        currentGanttData
      })
      .catch((error) => {
        console.log(error, 'error create all resposable and tag');
        const responseSave = error.response
          ? {
              status: error.response.status,
              statusText: error.response.statusText,
              data: error.response.data,
              sector_id
            }
          : { error };
        Sentry.withScope((scope) => {
          scope.setExtra('responseSave', responseSave);
          activities.forEach((activity) =>
            scope.setExtra(`activity_id ${activity.id}`, activity)
          );
          Sentry.captureException(
            new Error('cant save activities a move de task')
          );
        });
        return false;
      });
    return res ? res.data : res;
  }

  async createAllBackUp(
    activities,
    sector_id,
    links,
    tasktDeleted,
    linksToDelete
  ) {
    const user = JSON.parse(localStorage.getItem('user'));
    const res = await this.http
      .post('activitys/allBackup', {
        activities: activities,
        sector_id: sector_id,
        links: links,
        email: user.email,
        tasktDeleted,
        linksToDelete
      })
      .catch((error) => {
        const responseSave = {
          status: error.response.status,
          statusText: error.response.statusText,
          data: error.response.data,
          sector_id
        };
        return false;
      });
    return res ? res.data : res;
  }

  async getLookahead(config) {
    const res = await this.http
      .post('activitys/lookahead', config)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async getLookaheadIds(config) {
    const res = await this.http
      .post('activitys/lookaheadbyids', config)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async getLookaheadFolower(config) {
    const res = await this.http
      .post('activitys/getLookaheadactivitiesbyfollower', config)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async getLookaheadWeeklyPlan(config) {
    const res = await this.http
      .post('activitys/lookaheadweeklyplan', config)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async getLookaheadWeeklyPlanCommitment(config) {
    const res = await this.http
      .post('activitys/lookaheadweeklyplancommitment', config)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async getLookaheadesource(config) {
    const res = await this.http
      .post('activitys/lookaheadresource', config)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async getLookaheadweekcommit(config) {
    const res = await this.http
      .post('activitys/lookaheadweekhead', config)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async getLookaheadweekcommitWeek(config) {
    const res = await this.http
      .post('activitys/lookaheadweekheadcommit', config)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async assignLookahead(config) {
    const res = await this.http
      .post('activitys/assignlookahead', config)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async assingCustomPonderator(config) {
    const res = await this.http
      .post('activitys/assigncustomponderator', config)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async updateTreeProgress(activityId) {
    const res = await this.http
      .post('activitys/updatetreeprogress', { id: activityId })
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async getCardData(activityId) {
    const res = await this.http.get(`activitys/card/byid/${activityId}`);
    return res?.data;
  }

  async removeSubcontract(activityId) {
    const res = await this.http
      .get('activitys/remove_sub_contract/' + activityId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async getTasksActivity(activityId) {
    const res = await this.http.get(`activitys/tasks/${activityId}`);
    return res?.data;
  }

  async deleteTaskByActivityId(activityIdList) {
    const res = await this.http.post(`activitys/transformActivityInParent`, {
      activities_id: activityIdList
    });
    return res?.data;
  }

  async getCalendarActivities(calendarId) {
    try {
      const res = await this.http.get(`activitys/calendar/${calendarId}`);
      return res ? res.data : res;
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setContext('get calendar activities', {
          message: error.message
        });
        Sentry.captureException(error);
      });

      throw error;
    }
  }
}
export const activityService = new ActivityService();
