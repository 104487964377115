/* eslint-disable quote-props */
import { createHttpClient } from '../utils/httpUtils';

import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';

class TaskService {
  constructor() {
    this.http = createHttpClient();
  }

  async show(taskId) {
    const res = await this.http.get('tasks/' + taskId).catch((error) => {
      console.log(error.response);
      return false;
    });
    return res ? res.data : res;
  }

  async showWithRouteArray(ids, sector) {
    const data = {
      ids,
      sector
    };
    const res = await this.http
      .post('tasks/withroutearray', data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async destroy(taskId) {
    const res = await this.http.delete('tasks/' + taskId).catch((error) => {
      console.log(error.response);
      return false;
    });
    return res ? res.data : res;
  }

  async create(task) {
    const copy = cloneDeep(task); // { ...task }
    copy.start_date = moment(task.start_date).format('YYYY/MM/DD H:mm');
    copy.end_date = moment(task.end_date).format('YYYY/MM/DD H:mm');
    const res = await this.http.post('tasks', copy).catch((error) => {
      console.log(error.response);
      return false;
    });
    return res ? res.data : res;
  }

  async duplicateAll(elements, sectorId, toActivityDuplicate) {
    let copy = [...elements];
    copy = copy.map((el) => {
      el.start_date = moment(el.start_date).format('YYYY/MM/DD H:mm');
      el.end_date = moment(el.end_date).format('YYYY/MM/DD H:mm');
      return el;
    });
    const res = await this.http
      .post('tasks/duplicateAll', {
        sectorId: sectorId,
        duplicateElements: copy,
        toActivityDuplicate: toActivityDuplicate
      })
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async update(task) {
    const copy = { ...task };
    if (!copy.tags) {
      copy.tags = [];
    }
    delete copy.activityReference;
    copy.start_date = moment(task.start_date).format('YYYY/MM/DD H:mm');
    copy.end_date = moment(task.end_date).format('YYYY/MM/DD H:mm');
    const res = await this.http.put('tasks/' + task.id, copy).catch((error) => {
      console.log(error.response);
      return false;
    });
    return res ? res.data : res;
  }

  async assingCustomPonderator(config) {
    const res = await this.http
      .post('tasks/assigncustomponderator', config)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async getLog(taskId) {
    const res = await this.http
      .get('tasks/log_update/' + taskId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async removeSubcontract(taskId) {
    const res = await this.http
      .get('tasks/remove_sub_contract/' + taskId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async removeResource(taskId, resource) {
    const data = {
      taskId,
      resource
    };
    const res = await this.http
      .post('tasks/remove_resource', data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async updateCustomCommitmentPercentaje(taskId, value) {
    const data = {
      taskId,
      value
    };
    const res = await this.http
      .post('tasks/update_custom_commitment', data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async getAllTasksInsideWeeklyRange({ start, end, sectorId }) {
    const res = await this.http
      .post('tasks/getalltasksinsideweeklyrange', { start, end, sectorId })
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async updateMassive(tasks) {
    const data = {
      tasks
    };
    const res = await this.http
      .post('tasks/update_massive', data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async getTasksByActivityId(activityId) {
    try {
      const res = await this.http.get(`tasks/activity/${activityId}`);
      return res?.data;
    } catch (error) {
      return false;
    }
  }
}
export const taskService = new TaskService();
