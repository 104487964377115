/* eslint-disable quote-props */
import { createHttpClient } from '../utils/httpUtils';
import * as Sentry from '@sentry/react';

class HolidayService {
  constructor() {
    this.http = createHttpClient();
  }

  async getCountries() {
    const res = await this.http.get('holidays/countries/').catch((error) => {
      console.log(error.response);
      return false;
    });

    return res ? res.data : res;
  }

  async importHolidays(calendarId, country, years) {
    try {
      if (!calendarId || !country || !years) {
        throw new Error('Data is missing');
      }

      const countries = [country];

      const res = await this.http.post(`holidays/associate/${calendarId}`, {
        countries,
        years
      });

      return res ? res.data : res;
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setContext('clone calendar', {
          message: error.message
        });
        Sentry.captureException(error);
      });

      throw error;
    }
  }
}

export const holidayService = new HolidayService();
