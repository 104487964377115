import { store } from '../../redux/store';
import { debounce } from 'lodash';
import { defaultViewService } from '../defaultViews.service';
import { setViews } from '../../redux/slices/ganttSlice';

const buildView = async (loading) => {
  const gantt = window.to_use_react_gantt;
  if (loading || !gantt) return;
  const { ganttState } = store.getState();
  if (!ganttState) return;
  const hasDefaultView = Object.keys(ganttState.views).length === 0;

  const data = {
    name: 'Default',
    flag: hasDefaultView ? 'create' : 'update',
    order: getSortFilter(ganttState),
    filters: getGeneralFilter(ganttState),
    zoom: getZoomLevel(ganttState),
    visualization: getViewOptionsFilter(ganttState),
    range: getRangeFilter(ganttState),
    columns: getColumnsFilter(ganttState),
    colors: getColorsFilter(ganttState),
    collapsed: getCollapsedFilter(ganttState)
  };

  if (!hasDefaultView) {
    data.id = ganttState.views.id;
  }

  const currentSector = JSON.parse(sessionStorage.getItem('currentSector'));

  const response = await defaultViewService.updateDefaultView(
    currentSector.id,
    data
  );

  if (response.id) {
    store.dispatch(setViews(response));
  }
};

const getGeneralFilter = (ganttState) => {
  const { showedFilters } = ganttState;
  return showedFilters;
};

const getRangeFilter = (ganttState) => {
  const { dateRangeToFilter } = ganttState;
  if (!dateRangeToFilter.length) {
    return {
      start: null,
      end: null
    };
  }

  return {
    start: dateRangeToFilter[0],
    end: dateRangeToFilter[1]
  };
};

const getSortFilter = () => {
  const gantt = window.to_use_react_gantt;
  return gantt?.lastOrderByQuery?.value;
};

const getColumnsFilter = (ganttState) => {
  const gantt = window.to_use_react_gantt;
  const currentColumns = gantt.config.columns.slice();
  const columnsWithNewProperties = currentColumns.map((column, index) => ({
    ...column,
    value: column.name,
    position: index
  }));
  return columnsWithNewProperties;
};

const getZoomLevel = () => {
  const gantt = window.to_use_react_gantt;
  const ZOOM_LEVEL_ID = gantt.ext.zoom.getCurrentLevel();
  let currentZoom = '';
  switch (ZOOM_LEVEL_ID) {
    case 0:
      currentZoom = 'YEARS';
      break;
    case 4:
      currentZoom = 'DAYS';
      break;
    case 3:
      currentZoom = 'WEEKS';
      break;
    case 2:
      currentZoom = 'MONTH';
      break;
    case 1:
      currentZoom = 'QUARTERS';
      break;
  }

  return { code: ZOOM_LEVEL_ID, type: currentZoom };
};

const getViewOptionsFilter = (ganttState) => {
  const { visualizationConfig } = ganttState;
  const {
    areLinksVisible = false,
    areSlackVisible = false,
    areBaselineVisible = false,
    areNumtasksVisible = false,
    isTodaylineVisible = false,
    areSubmittalsVisible = false
  } = visualizationConfig;

  return {
    areLinksVisible,
    areSlackVisible,
    areBaselineVisible,
    areNumtasksVisible,
    isTodaylineVisible,
    areSubmittalsVisible
  };
};

const getColorsFilter = (ganttState) => {
  const gantt = window.to_use_react_gantt;
  const { visualizationConfig } = ganttState;
  const { colorSchemeType } = visualizationConfig;

  return {
    bar: colorSchemeType,
    wbs: Boolean(gantt.isActiveColorWBS)
  };
};

const getCollapsedFilter = (ganttState) => {
  const gantt = window.to_use_react_gantt;
  return gantt
    .getTaskBy((activity) => activity.$open === false)
    .map((activity) => activity.id);
};

const updateDefaultView = debounce(
  (loading = false) => buildView(loading),
  2000
);

export { updateDefaultView };
