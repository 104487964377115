/* eslint-disable quote-props */
import { createHttpClient } from '../utils/httpUtils';
import * as Sentry from '@sentry/react';
import { base } from './base';
export const AUTH_URL = `https://developer.api.autodesk.com/authentication/v2/authorize?response_type=code&client_id=${base.autodesk.client}&redirect_uri=${encodeURIComponent(
  base.autodesk.redirectUri
)}&scope=data:create%20data:read%20data:write%20account:read`;
export const credentials = `${base.autodesk.client}:${base.autodesk.secret}`;
export const Authorization = Buffer.from(credentials, 'utf-8').toString(
  'base64'
);
class AutodeskIntegrationService {
  constructor() {
    this.http = createHttpClient();
  }

  async show(autodeskIntegrationId) {
    const res = await this.http
      .get('autodeskintegrations/' + autodeskIntegrationId)
      .catch((error) => {
        Sentry.captureException(error, {
          tags: {
            feature: 'autodesk-integration',
            action: 'show'
          },
          extra: {
            response: error.response
          }
        });

        return false;
      });
    return res ? res.data : res;
  }

  async destroy(autodeskintegrationsId) {
    const res = await this.http
      .delete('autodeskintegrations/' + autodeskintegrationsId)
      .catch((error) => {
        Sentry.captureException(error, {
          tags: {
            feature: 'autodesk-integration',
            action: 'destroy'
          },
          extra: {
            response: error.response
          }
        });
        return false;
      });
    return res ? res.data : res;
  }

  async create(data) {
    const res = await this.http
      .post('autodeskintegrations/', data)
      .catch((error) => {
        Sentry.captureException(error, {
          tags: {
            feature: 'autodesk-integration',
            action: 'create'
          },
          extra: {
            response: error.response
          }
        });
        return false;
      });
    return res ? res.data : res;
  }

  async update(data) {
    const res = await this.http
      .put('autodeskintegrations/' + data.id, data)
      .catch((error) => {
        Sentry.captureException(error, {
          tags: {
            feature: 'autodesk-integration',
            action: 'update'
          },
          extra: {
            response: error.response
          }
        });
        return false;
      });
    return res ? res.data : res;
  }

  async exchangeToken(code) {
    try {
      const response = await fetch(base.autodesk.authWithCodeUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: 'Basic ' + Authorization
        },
        body: new URLSearchParams({
          client_secret: base.autodesk.secret,
          grant_type: 'authorization_code',
          code,
          redirect_uri: base.autodesk.redirectUri
        })
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error || 'Failed to exchange token');
      }

      return data;
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          feature: 'autodesk-integration',
          action: 'exchangeToken'
        },
        extra: {
          response: error.response
        }
      });

      return {
        isError: true,
        message: 'Failed to exchange token with Autodesk'
      };
    }
  }

  async getByCompany(companyId) {
    const res = await this.http
      .get('autodeskintegrations/showByCompany/' + companyId)
      .catch((error) => {
        Sentry.captureException(error, {
          tags: {
            feature: 'autodesk-integration',
            action: 'showByCompany'
          },
          extra: {
            response: error.response
          }
        });
        return false;
      });
    return res ? res.data : res;
  }

  async getAutodeskHubsAndProjects(companyId) {
    const res = await this.http
      .post('autodeskintegrations/gethubsandprojects', {
        organizationId: companyId
      })
      .catch((error) => {
        Sentry.captureException(error, {
          tags: {
            feature: 'autodesk-integration',
            action: 'gethubsandprojects'
          },
          extra: {
            response: error.response
          }
        });
        return false;
      });
    return res ? res.data : res;
  }

  async getAutodeskAssignations() {
    const res = await this.http
      .post('autodeskassignations/showAllAutodeskAssignations/')
      .catch((error) => {
        Sentry.captureException(error, {
          tags: {
            feature: 'autodesk-integration',
            action: 'showallautodeskassignations'
          },
          extra: {
            response: error.response
          }
        });
        return false;
      });
    return res ? res.data : res;
  }

  async createAllAutodeskAssignations(data) {
    const res = await this.http
      .post('autodeskassignations/createAllAutodeskAssignations/', {
        autodeskAssignations: data
      })
      .catch((error) => {
        Sentry.captureException(error, {
          tags: {
            feature: 'autodesk-integration',
            action: 'createallautodeskassignations'
          },
          extra: {
            response: error.response
          }
        });
        return false;
      });
    return res ? res.data : res;
  }

  async updateAllAutodeskAssignations(data) {
    const res = await this.http
      .post('autodeskassignations/updateAllAutodeskAssignations/', {
        autodeskAssignations: data
      })
      .catch((error) => {
        Sentry.captureException(error, {
          tags: {
            feature: 'autodesk-integration',
            action: 'updateallautodeskassignations'
          },
          extra: {
            response: error.response
          }
        });
        return false;
      });
    return res ? res.data : res;
  }

  async deleteAllAutodeskAssignations(data) {
    const res = await this.http
      .post('autodeskassignations/deleteAllAutodeskAssignations/', {
        autodeskAssignations: data
      })
      .catch((error) => {
        Sentry.captureException(error, {
          tags: {
            feature: 'autodesk-integration',
            action: 'deleteallautodeskassignations'
          },
          extra: {
            response: error.response
          }
        });
        return false;
      });
    return res ? res.data : res;
  }

  async getSectorAutodeskAssignation(sectorId) {
    const res = await this.http
      .post('autodeskassignations/getSectorAutodeskAssignation/', {
        sectorId
      })
      .catch((error) => {
        Sentry.captureException(error, {
          tags: {
            feature: 'autodesk-integration',
            action: 'getSectorAutodeskAssignation'
          },
          extra: {
            response: error.response
          }
        });
        return false;
      });
    return res ? res.data : res;
  }
}

export const autodeskIntegrationService = new AutodeskIntegrationService();
