/* eslint-disable quote-props */
import * as Sentry from '@sentry/react';
import { sentryLogger } from '../monitor/monitor';
import { createHttpClient } from '../utils/httpUtils';
import { base } from './base';

const baseURL =
  base.backendExportable || 'https://www.backendservice.link/api/';

class ActivityPdfService {
  constructor() {
    this.http = createHttpClient({
      baseURL,
      maxContentLength: Infinity,
      maxBodyLength: Infinity
    });
  }

  async exportToPDF(data) {
    const res = await this.http
      .post('exportable/exportPdf', data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async exportP6XML(data) {
    try {
      const { data: responseData } = await this.http.post(
        'exportable/exportP6XML',
        data
      );
      return responseData;
    } catch (error) {
      sentryLogger.error('exportP6XML', {
        error
      });
      throw error;
    }
  }

  async checkBackendAccess() {
    try {
      await this.http.get('auth/healthCheck');
      return true;
    } catch (error) {
      return false;
    }
  }
}

export const activityPdfService = new ActivityPdfService();
