/* eslint-disable quote-props */
import { trackingEvent } from '../analytics';
import { AMPLITUDE_SERVICE } from '../analytics/constants';
import { getBasicAmplitudEventProperties } from '../analytics/utils';
import { createHttpClient } from '../utils/httpUtils';
import * as Sentry from '@sentry/react';

class ActivityRelationService {
  constructor() {
    this.http = createHttpClient();
  }

  async show(activityRelationId) {
    const res = await this.http
      .get('activityrelations/' + activityRelationId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async destroy(activityRelationId) {
    const res = await this.http
      .delete('activityrelations/' + activityRelationId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async create(gantt) {
    const res = await this.http
      .post('activityrelations', gantt)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async update(gantt) {
    const res = await this.http
      .put('activityrelations/' + gantt.id, gantt)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async createAll(activityrelation) {
    const res = await this.http
      .post('activityrelations/all', { activityrelation })
      .catch((error) => {
        Sentry.withScope((scope) => {
          scope.setContext('save_schedule_failed_dev', {
            activityrelation
          });
          Sentry.captureException(error);
        });
        trackingEvent(
          'save_schedule_failed_dev',
          {
            ...getBasicAmplitudEventProperties(),
            error_information: error
          },
          AMPLITUDE_SERVICE
        );
        return false;
      });
    return res ? res.data : res;
  }
}
export const activityRelationService = new ActivityRelationService();
